<template>
  <b-modal id="modal-forward" centered class="modal-default" @show="updateDirectives">
    <div class="modal-default-itens">
      <div class="modal-default-itens-header">
        <button class="modal-default-itens-close" @click="$bvModal.hide('modal-forward')">
          <p>X</p>
        </button>
      </div>
	  <div class="mask" id="maskForward"></div>
      <div class="modal-default-itens-body">
        <div class="modal-default--header">
          <svg id="Group_201" data-name="Group 201" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="264.183" height="66.11" viewBox="0 0 264.183 66.11">
			<defs>
				<clipPath id="clip-path">
				<rect id="Rectangle_71" data-name="Rectangle 71" width="264.183" height="66.11" fill="none"/>
				</clipPath>
			</defs>
			<g id="Group_200" data-name="Group 200" clip-path="url(#clip-path)">
				<path id="Path_3424" data-name="Path 3424" d="M299.829,1.2A.8.8,0,0,1,300.635.4h.169a.841.841,0,0,1,.74.429L304.3,5.139,307.067.812A.8.8,0,0,1,307.795.4h.169a.8.8,0,0,1,.806.806V8.816a.791.791,0,0,1-.806.792.8.8,0,0,1-.792-.792V3.359L304.949,6.7a.747.747,0,0,1-1.339,0L301.4,3.385V8.829a.777.777,0,0,1-.793.78.766.766,0,0,1-.78-.78Z" transform="translate(-221.022 -0.292)" fill="#ce0e2d"/>
				<path id="Path_3425" data-name="Path 3425" d="M352.583,4.729V4.7a4.756,4.756,0,0,1,9.512-.026V4.7a4.756,4.756,0,0,1-9.512.026m7.835,0V4.7a3.116,3.116,0,0,0-3.093-3.236,3.073,3.073,0,0,0-3.067,3.21V4.7a3.106,3.106,0,0,0,3.093,3.223,3.062,3.062,0,0,0,3.067-3.2" transform="translate(-259.911 0.001)" fill="#ce0e2d"/>
				<path id="Path_3426" data-name="Path 3426" d="M406.88,8.987,403.7,1.5a.892.892,0,0,1-.091-.377.788.788,0,0,1,.806-.78.836.836,0,0,1,.819.572l2.625,6.562L410.519.866a.823.823,0,0,1,.767-.52.774.774,0,0,1,.792.766A.925.925,0,0,1,412,1.45l-3.2,7.537a.952.952,0,0,1-.923.65h-.091a.948.948,0,0,1-.91-.65" transform="translate(-297.523 -0.255)" fill="#ce0e2d"/>
				<path id="Path_3427" data-name="Path 3427" d="M453.985,1.139a.8.8,0,0,1,1.6,0V8.779a.8.8,0,0,1-1.6,0Z" transform="translate(-334.66 -0.255)" fill="#ce0e2d"/>
				<path id="Path_3428" data-name="Path 3428" d="M480.682,1.2A.8.8,0,0,1,481.488.4h.169a.842.842,0,0,1,.741.429l2.755,4.314L487.92.812A.8.8,0,0,1,488.648.4h.169a.8.8,0,0,1,.806.806V8.816a.791.791,0,0,1-.806.792.8.8,0,0,1-.793-.792V3.359L485.8,6.7a.746.746,0,0,1-1.338,0l-2.209-3.313V8.829a.777.777,0,0,1-.792.78.766.766,0,0,1-.78-.78Z" transform="translate(-354.34 -0.292)" fill="#ce0e2d"/>
				<path id="Path_3429" data-name="Path 3429" d="M534.968,8.883V1.4a.793.793,0,0,1,.806-.806h5.289a.721.721,0,0,1,.715.715.712.712,0,0,1-.715.714h-4.5V4.388h3.911a.724.724,0,0,1,.715.728.71.71,0,0,1-.715.7h-3.911V8.26h4.561a.721.721,0,0,1,.715.715.712.712,0,0,1-.715.715h-5.354a.793.793,0,0,1-.806-.806" transform="translate(-394.358 -0.437)" fill="#ce0e2d"/>
				<path id="Path_3430" data-name="Path 3430" d="M579.465,1.165a.8.8,0,0,1,.806-.806h.169a1.006,1.006,0,0,1,.832.468l4.548,5.887V1.126a.786.786,0,0,1,1.572,0v7.64a.777.777,0,0,1-.78.793h-.065a1.007,1.007,0,0,1-.832-.481l-4.678-6.056v5.77a.786.786,0,0,1-1.572,0Z" transform="translate(-427.159 -0.255)" fill="#ce0e2d"/>
				<path id="Path_3431" data-name="Path 3431" d="M630.125,2.074h-2.209a.741.741,0,1,1,0-1.481h6.042a.741.741,0,0,1,0,1.481h-2.222V8.961a.806.806,0,0,1-1.611,0Z" transform="translate(-462.329 -0.437)" fill="#ce0e2d"/>
				<path id="Path_3432" data-name="Path 3432" d="M669.892,4.729V4.7a4.756,4.756,0,0,1,9.512-.026V4.7a4.756,4.756,0,0,1-9.512.026m7.836,0V4.7a3.117,3.117,0,0,0-3.093-3.236,3.073,3.073,0,0,0-3.067,3.21V4.7a3.106,3.106,0,0,0,3.093,3.223,3.062,3.062,0,0,0,3.067-3.2" transform="translate(-493.819 0.001)" fill="#ce0e2d"/>
				<path id="Path_3433" data-name="Path 3433" d="M123.79,144.085a3.207,3.207,0,0,1,3.441-3.039,3.252,3.252,0,0,1,3.036,3.495l-.236,22.546a3.247,3.247,0,0,1-6.477-.456Z" transform="translate(-91.073 -103.967)"/>
				<path id="Path_3434" data-name="Path 3434" d="M150.955,152.649a3.207,3.207,0,0,1,2.658,3.743,3.252,3.252,0,0,1-3.8,2.649l-10.384.047a3.247,3.247,0,0,1,1.137-6.393Z" transform="translate(-100.831 -112.523)"/>
				<path id="Path_3435" data-name="Path 3435" d="M169.148,166.407a3.247,3.247,0,0,1-6.393-1.137l3.626-9.731a3.252,3.252,0,0,1,3.8-2.649,3.207,3.207,0,0,1,2.6,3.786Z" transform="translate(-119.938 -112.666)"/>
				<path id="Path_3436" data-name="Path 3436" d="M0,110.925A3.276,3.276,0,0,1,3.327,107.6H14.81c8.8,0,14.273,4.99,14.273,12.556v.107c0,8.424-6.761,12.824-15.025,12.824H6.6v9.068a3.252,3.252,0,0,1-3.273,3.273A3.264,3.264,0,0,1,0,142.154ZM14.273,127.13c4.99,0,8.1-2.791,8.1-6.707v-.107c0-4.4-3.166-6.707-8.1-6.707H6.6V127.13Z" transform="translate(0 -79.317)"/>
				<path id="Path_3437" data-name="Path 3437" d="M233.913,141.532H213.63a3,3,0,1,0,0,6.01h13.493l-15.489,17.345a3.247,3.247,0,0,0,4.591,4.592l14.414-16.141v13.82a3.3,3.3,0,0,0,6.6,0v-22.3a3.276,3.276,0,0,0-3.327-3.327" transform="translate(-155.265 -104.332)"/>
				<path id="Path_3438" data-name="Path 3438" d="M257.635,95.483a3.252,3.252,0,0,0,4.629,0,3.207,3.207,0,0,0-.038-4.591l-8.542-5.9a3.247,3.247,0,0,0-4.591,4.591Z" transform="translate(-182.923 -61.947)"/>
				<path id="Path_3439" data-name="Path 3439" d="M400.115,116.989h-1.127a2.7,2.7,0,0,1-2.737-2.683,2.748,2.748,0,0,1,2.737-2.736h1.127v-2.093c0-3.112.8-5.473,2.307-6.975a8.58,8.58,0,0,1,6.385-2.254,18.726,18.726,0,0,1,3.273.268,2.7,2.7,0,0,1-.483,5.366,10.418,10.418,0,0,0-1.342-.108c-2.469,0-3.756,1.342-3.756,4.293v1.556h5.044a2.669,2.669,0,0,1,2.736,2.683,2.7,2.7,0,0,1-2.736,2.683h-4.937v19.8a3.247,3.247,0,0,1-6.493,0Z" transform="translate(-292.102 -73.898)"/>
				<path id="Path_3440" data-name="Path 3440" d="M585.019,168.6c-8.21,0-14.542-5.956-14.542-14.7v-.108c0-8.1,5.741-14.756,13.844-14.756,9.015,0,13.468,7.4,13.468,13.951a3.036,3.036,0,0,1-3.059,3.112H576.97c.7,4.615,3.97,7.19,8.156,7.19a10.185,10.185,0,0,0,6.707-2.468,2.417,2.417,0,0,1,1.664-.591,2.555,2.555,0,0,1,2.576,2.629,2.834,2.834,0,0,1-.859,1.985,14.546,14.546,0,0,1-10.195,3.756m6.439-16.8c-.429-4.186-2.9-7.459-7.19-7.459-3.971,0-6.761,3.059-7.351,7.459Z" transform="translate(-420.534 -102.492)"/>
				<path id="Path_3441" data-name="Path 3441" d="M820.546,139.818V124.9h-1.073a2.79,2.79,0,1,1,0-5.581h1.073v-4.829a3.247,3.247,0,0,1,6.493,0v4.829h5.1a2.791,2.791,0,1,1,0,5.581h-5.1v13.9c0,2.522,1.288,3.541,3.488,3.541a15.352,15.352,0,0,0,1.61-.161,2.737,2.737,0,0,1,2.737,2.683,2.794,2.794,0,0,1-1.717,2.522,12.913,12.913,0,0,1-4.454.751c-4.775,0-8.156-2.093-8.156-8.317" transform="translate(-602.027 -82.025)"/>
				<path id="Path_3442" data-name="Path 3442" d="M915.744,168.6c-8.21,0-14.542-5.956-14.542-14.7v-.108c0-8.1,5.741-14.756,13.844-14.756,9.015,0,13.468,7.4,13.468,13.951a3.036,3.036,0,0,1-3.058,3.112H907.695c.7,4.615,3.971,7.19,8.156,7.19a10.183,10.183,0,0,0,6.707-2.468,2.418,2.418,0,0,1,1.664-.591,2.555,2.555,0,0,1,2.576,2.629,2.834,2.834,0,0,1-.859,1.985,14.545,14.545,0,0,1-10.195,3.756m6.439-16.8c-.429-4.186-2.9-7.459-7.19-7.459-3.971,0-6.761,3.059-7.351,7.459Z" transform="translate(-664.332 -102.492)"/>
				<path id="Path_3443" data-name="Path 3443" d="M717.238,144.137H700.367a3.247,3.247,0,0,0-3.038-3.091,3.207,3.207,0,0,0-3.441,3.039l-.236,22.546a3.247,3.247,0,0,0,6.477.456l.177-16.94h13.658v16.611a3.3,3.3,0,0,0,6.6,0V147.464a3.276,3.276,0,0,0-3.327-3.327" transform="translate(-511.328 -103.968)"/>
				<path id="Path_3444" data-name="Path 3444" d="M502.085,145.472a3.29,3.29,0,0,0-2.162-1.379l-10.384.047a3.318,3.318,0,0,0-.548-.048,3.247,3.247,0,0,0-3.034-3.046,3.207,3.207,0,0,0-3.441,3.039l-.236,22.546a3.247,3.247,0,0,0,6.477.456l.173-16.556,5.946-.027-2.261,6.067a3.247,3.247,0,0,0,6.393,1.137l3.625-9.731a3.289,3.289,0,0,0-.549-2.505" transform="translate(-355.513 -103.967)"/>
			</g>
		  </svg>

          <h4 class="v-content-forward" v-contentForward="{section: 'Modal Pra Frente', removeHtml: true}">Vamos juntos?</h4>
        </div>
        <div class="modal-default--text">
          <p class="v-content-forward" v-contentForward="{section: 'Modal Pra Frente'}">Somos <span>+ de 3600 colaboradores + de 30 marcas + de 50.000 Clientes. </span>
              Na Delta quando nos propomos realizar um objetivo <span>somos sempre +</span>.
              Em cada canto de uma terra, cidade ou país a Delta é um cartão de boas-vindas que marca um território.
              Clientes tornam-se amigos, consumidores apaixonados e nós, embaixadores da nossa Marca.
          </p>
          <h2 class="v-content-forward" v-contentForward="{section: 'Modal Pra Frente'}">Vamos conquistar + território, juntos?</h2>
          <p class="v-content-forward" v-contentForward="{section: 'Modal Pra Frente'}">
            Sabe mais sobre este projeto e conquista + território em:
            <span class="d-block">+ CLIENTES + AMIGOS</span>
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import axios from 'axios';
const allContents = [];
export default {
	data() {
	return {
	}
	},
	computed: {
		...mapState('App', ['loading']),
	},
	methods: {
		updateDirectives() {
			const allElements = document.querySelectorAll('.v-content-forward');
			allContents.splice(0, allContents.length); // clear the contents array
			allElements.forEach((el) => {
				const params = el.dataset.params ? JSON.parse(el.dataset.params) : {};
				params.content = el.innerHTML.trim();
				(!('type' in params) ? params.type = 'text' : null);
				params.theme = process.env.VUE_APP_THEME;
				params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
				allContents.push(params);
			});
		},
	},
	directives: {
		contentForward: {
			inserted: async function (el, binding) {
				const allElements = document.querySelectorAll('.v-content-forward');
				let params = binding.value ? binding.value : {};
				params.content = el.innerHTML.trim();
				(!('type' in params) ? params.type = 'text' : null);
				params.theme = process.env.VUE_APP_THEME;
				params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
				allContents.push(params);

				if (allElements.length == allContents.length) {
					store.dispatch('App/loading', true);
					await axios({
						method: "POST",
						url: '/api/v1/site/get-all-contents',
						data: { allContents }
					})
						.then(response => response.data)
						.then((data) => {

							const allElements = document.querySelectorAll('.v-content-forward');
							allContents.forEach((el, index) => {
								if (el.type == 'text') {
									allElements.forEach((el, index) => {
										el.innerHTML = data.data[index];
									});
								}
							});
							store.dispatch('App/loading', false);
							document.getElementById('maskForward').remove();
						});
				}
			},

		}
	},
}
</script>

<style lang="scss" scoped>
  @import '../index.scss';

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  top: 0;
  left: 0;
}
</style>
